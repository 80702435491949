<template>
    <div class="edit-video flex-column" v-loading="isLoading" element-loading-text="保存中" element-loading-spinner="el-icon-loading">
        <el-form ref="relatedVideoForm" :model="videoData" :rules="type?chapterDataRules:{}" label-width="200px">
            <el-form-item label="相关视频名称：" prop="correlation_name">
                <el-col :span="8">
                    <el-input :style="type===2?'display:none':''" v-model="videoData.correlation_name" placeholder="请输入视频名称"/>
                </el-col>
            </el-form-item>
            <el-form-item label="关联相关动态漫：" prop="animation_list">
                <el-col :span="8">
                    <el-select
                      style="display:block;"
                      v-model="videoData.animation_list"
                      multiple
                      filterable
                      remote
                      reserve-keyword
                      placeholder="搜索动态漫名"
                      :remote-method="remoteMethod"
                      :loading="loading">
                      <el-option
                        v-for="item in anComicList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="相关视频封面：" prop="correlation_cover_16_8">
                <videoCover :type="type" :correlationCover168.sync="videoData.correlation_cover_16_8" :correlation_cover_domain.sync="videoData.correlation_cover_domain"/>
            </el-form-item>
            <el-form-item label="上传视频：" prop="correlation_video_id">
              <relatedVideoUploader :file_size="videoData.size" :duration.sync="videoData.duration" :type="type" :correlation_video_id.sync="videoData.correlation_video_id" :correlation_name="videoData.correlation_name"/>
            </el-form-item>
        </el-form>
        <!-- buttons -->
        <div class="mmiddle btns">
            <el-button type="primary" @click="submitFormHandle('relatedVideoForm')">保存</el-button>
            <el-button @click="cancelHandle('relatedVideoForm')">取消</el-button>
        </div>
        <el-dialog class="fulldialog" title="预览" width="800px" :append-to-body="true" :visible.sync="showDialog">
          <el-image
          :src="`${videoData.correlation_cover_domain}${videoData.correlation_cover_16_8}`"></el-image>
        </el-dialog>
    </div>
</template>
<script>
import { formMixin, dialogMixin, dataMixin } from '@/mixins';
import videoCover from './videoCover';
import relatedVideoUploader from './relatedVideoUploader';

export default {
    mixins: [formMixin, dialogMixin, dataMixin],
    data() {
        return {
            loading: false,
            showDialog: false,
            fullDialogImage: '',
            isLoading: false,
            type: 'add', // 编辑类型: edit, 2 add
            isChange: false, // 是否编辑
            isSubmit: false, // 是否提交
            comicInfo: {},
            anComicList: [],
            videoData: {
                animation_list: [],
                correlation_name: null,
                correlation_cover_16_8: null,
                correlation_video_id: '',
                correlation_cover_domain: ''
            },
            chapterDataRules: {
                correlation_name: [
                    { required: true, message: '请填写相关视频名称', trigger: 'blur' },
                    { min: 0, max: 30, message: '最多30个字符', trigger: 'blur' }
                ],
                animation_list: [
                    { required: true, message: '请填写关联相关动态漫', trigger: 'blur' }
                ],
                correlation_video_id: [
                    { required: true, message: '请上传相关视频' }
                ],
                correlation_cover_16_8: [
                    { required: true, message: '请上传相关视频封面', trigger: 'change' }
                ]
            }
        };
    },
    watch: {
        videoData: {
            deep: true,
            handler() {
                if (this.type === 'edit') this.isChange = this.checkEditChange();
                else if (this.type === 'add') this.isChange = this.checkAddChange();
            }
        }
    },
    destroyed() {
        this.$root.$off('fullscreen');
    },
    async created() {
        this.initQuery();
        await this.initData();
        this.$root.$on('fullscreen', this.fullscreenHandle);
    },
    methods: {
        // 取消编辑
        cancelHandle(formName) {
            this.clearFormValidate(formName);
            const { editType } = this.$route.query;
            if (editType === 'add') this.resetDataField('videoData');
            else {
                if (editType === 'edit') this.$router.back();
            }
        },
        remoteMethod(query_text) {
            if (query_text !== '') {
                this.loading = true;
                setTimeout(() => {
                    this.$api('getAnComicList', {
                        query_text,
                        query_type: 'all',
                        show_status: -2,
                        page_size: 50,
                        page_index: 1
                    }).then(res => {
                        this.loading = false;
                        this.anComicList = res.data.rows.map(item => {
                            return {
                                label: item.animation_name,
                                value: item.animation_id
                            };
                        });;
                    });
                }, 200);
            } else {
                this.anComicList = [];
            }
        },
        // 初始化传递参数
        async initQuery() {
            this.resetDataField('videoData');
            const { editType = 'add', correlation_id } = this.$route.query;
            this.type = editType;

            this.videoData = {
                ...this.videoData,
                correlation_id
            };
            if (this.type === 'add') {
                this.isSubmit = false;
                this.videoData.sourceObj = this.$utils.cloneDeep(this.videoData);
                this.originData = this.$utils.cloneDeep(this.videoData);
                if (this.videoData.type) this.type = this.videoData.type;
            }
        },
        // 初始化数据
        async initData() {
            await this.getRelatedVideorData();
        },
        // 查看放大图
        fullscreenHandle(src) {
            if (!src) return;
            this.showDialog = true;
            this.fullDialogImage = src;
        },
        // 获取相关视频数据
        getRelatedVideorData() {
            if (this.type === 'add') return;
            const { correlation_id } = this.videoData;
            return this.$api('getRelatedVideoInfo', { correlation_id }).then(({ data }) => {
                if (this.$utils.isEmptyObject(data)) {
                    this.$message.error('当前相关视频不存在！');
                    return this.$router.back();
                }
                data.animation_list = JSON.parse(data.animation_list);
                this.anComicList = data.animation_infos.map(item => {
                    return {
                        label: item.animation_name,
                        value: item.animation_id
                    };
                });
                this.videoData = { ...this.videoData, ...data };
            });
        },
        // 检查是否做了修改
        checkEditChange() {
            const chapterDataClone = this.$utils.cloneDeep(this.videoData);
            const sourceObjClone = this.$utils.cloneDeep(chapterDataClone.sourceObj);
            Reflect.deleteProperty(chapterDataClone, 'sourceObj');
            if (JSON.stringify(chapterDataClone) === JSON.stringify(sourceObjClone)) return false;
            else return true;
        },
        // 检查新增数据是否修改
        checkAddChange() {
            if (JSON.stringify(this.videoData) === JSON.stringify(this.originData)) return false;
            else return true;
        },
        // 保存
        async submitFormHandle() {
            const valid = await this.validateForm('relatedVideoForm');
            if (!valid) {
                this.message && this.message.close();
                this.message = this.$message.error('相关视频信息填写有误!');
                return false;
            } else if (!this.isChange) {
                this.$message.error('相关视频未做修改');
                return false;
            }

            let url;
            if (this.type === 'add') url = 'addRelatedVideo';
            else if (this.type === 'edit') url = 'editRelatedVideo';
            this.$utils.confirm('', `请确认保存页面中的所有内容`, '保存中...', (action, instance, done) => {
                this.isLoading = true;
                done();
                const {
                    correlation_id,
                    animation_list,
                    correlation_name,
                    correlation_cover_16_8,
                    correlation_video_id,
                    correlation_cover_domain
                } = this.$utils.cloneDeep(this.videoData);
                const postData = {
                    correlation_id,
                    animation_list,
                    correlation_name,
                    correlation_cover_16_8,
                    correlation_video_id,
                    correlation_cover_domain
                };
                return this.$api(url, postData).then(res => {
                    this.isLoading = false;
                    this.isSubmit = true;
                    this.prevChapterId = res.data.chapter_id;
                    this.$message.success({
                        message: '提交成功！',
                        duration: '1000',
                        onClose: () => {
                            this.$router.back();
                        }
                    });
                }).catch(er => { this.isLoading = false; });
            });
        }
    },
    components: {
        videoCover,
        relatedVideoUploader
    }
};
</script>
<style lang="scss">
    @import "../../../../styles/var";
    .edit-video{
        padding-top: 24px;
        padding-bottom: 12px;
        font-size: 12px;
        height: 100%;
        box-sizing: border-box;
        background-color: #FFF;
        .head-label {
          font-size: 14px;
          color: #606266;
          width: 80px;
          display: inline-block;
        }
        .el-form{
            flex-grow: 1;
            padding: 0 10px 0 0;
            overflow-x: hidden;
            overflow-y: auto;

            .autoResize{
                overflow: hidden;
                align-items: center;
            }
        }
        .search-box-message{
            font-size: 12px;
            color: $color-info;
            i{
                margin-right: 5px;
            }
        }
        &-textarea {
            position: relative;
            textarea{
                padding-bottom: 20px;
            }
            &-length {
                position: absolute;
                bottom: 5px;
                left: calc(100% + 10px);
                line-height: 1;
                color: #999;
            }
        }
        &-tips{
            font-size: 12px;
            color: #909399;
        }
        &-theme.el-checkbox{
            margin-left: 0;
            margin-right: 30px;
        }
        &-tips{
            >div{
                font-size: 12px;
                color: #999;
            }
        }
        .btns{
            margin-bottom: 10px;
        }
    }
    .fulldialog{
        .is-fullscreen{
            background: rgba(255, 255,255,0);
        }
        .el-dialog__header{
            border: none;
        }
        .el-dialog__body{
            height: calc(100% - 30px);
        }
        i.el-dialog__close{
            color: #ffff;
        }
        &-content{
            width: 700px;
            height: 375px;
            margin: 0 auto;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: relative;
        }
        &-focus{
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            font-size: 36px;
            border-width: 6px;
            padding: 0 15px;
            z-index: 999999;
            height: 42px;
            font-weight: bold;
        }
    }
</style>
