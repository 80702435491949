<template>
  <div class="video-uploader-box">
      <el-upload
        ref="uploader"
        class="video-uploader"
        :show-file-list="false"
        drag
        action=""
        :accept="$config.acceptVideo"
        :auto-upload="false"
        :disabled="disabledUpload"
        :on-change="onChange">
        <div slot="trigger" v-if="correlation_video_id">
          <SvgIcon icon="play"></SvgIcon>
        </div>
        <div slot="trigger" v-else>
          <i class="el-icon-plus"></i>
          <div class="el-upload__text">
              <p class="text">拖拽视频到此处或点击上传；最多可上传一个视频</p>
              <p class="el-upload__tip">视频建议为MP4格式，清晰度不低于1080P，大小小于1G</p>
          </div>
        </div>
    </el-upload>
    <div class="right" v-if="uploadStatus === 'uploading'">
        <div class="hd">
            <el-progress :percentage="authProgress" :status="status" :stroke-width="14" :show-text="false"></el-progress>
            <el-button type="warning" @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="previewHandle({correlation_video_id})">预览</el-button>
        </div>
        <div class="info">
            <span class="size">{{uploadedSize | convertFileSizeFilter}}/{{fileSize | convertFileSizeFilter}}</span>
            <span class="speed">速度：{{speed}}</span>
            <span class="progress">已上传：{{authProgress}}%</span>
        </div>
        <div class="meta">
            <span>视频时长：{{duration | reCovertTimeFilter}}</span>
            <span>视频大小：{{fileSize | convertFileSizeFilter}}</span>
        </div>
    </div>
    <div class="right" v-else-if="uploadStatus === 'edit'">
        <div class="hd">
            <el-button type="danger" @click="handleDelete">删除</el-button>
            <el-button type="primary" @click="previewHandle({animation_id, correlation_video_id})">预览</el-button>
        </div>
        <div class="meta">
            <span>视频时长：{{duration | reCovertTimeFilter}}</span>
            <span v-if="fileSize">视频大小：{{fileSize | convertFileSizeFilter}}</span>
        </div>
    </div>
    <div class="right" v-else-if="uploadStatus === 'success'">
        <div class="hd">
            <i class="el-icon-success"></i> <span class="text-success">上传成功</span>
            <el-button type="danger" @click="handleDelete">删除</el-button>
            <el-button type="primary" @click="previewHandle({animation_id, correlation_video_id})">预览</el-button>
        </div>
        <div class="meta">
            <span>视频时长：{{duration | reCovertTimeFilter}}</span>
            <span>视频大小：{{fileSize | convertFileSizeFilter}}</span>
        </div>
    </div>
    <div class="right" v-else-if="uploadStatus === 'failed'">
        <div class="hd">
            <i class="el-icon-error"></i> <span class="text-error">上传失败</span>
            <el-button type="primary" @click="handleReupload">重新上传</el-button>
        </div>
        <div class="meta">
            <span>视频时长：{{duration | reCovertTimeFilter}}</span>
        </div>
    </div>
  </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-18 15:23:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-19 14:11:46
 * @Description: 章节封面
 * @Company: 成都二次元动漫
 */
import customerImage from '@/components/customerImage';
export default {
    props: {
        type: String, // 编辑类型: 0 view, 1 edit, 2 add
        correlation_name: String,
        duration: Number,
        correlation_video_id: {},
        file_size: {}
    },
    computed: {
        uploadedSize() {
            return `${(this.fileSize * this.authProgress / 100).toFixed(2)}M`;
        }
    },
    data() {
        return {
            disabledUpload: false,
            timeout: '',
            partSize: '',
            parallel: '',
            retryCount: '',
            retryDuration: '',
            region: 'cn-shanghai',
            userId: '1039490146344868',
            file: null,
            authProgress: 0,
            uploadDisabled: true,
            resumeDisabled: true,
            pauseDisabled: true,
            uploader: null,
            statusText: '',
            uploadStatus: 'default', // default, edit, uploading, success, failed
            fileSize: '',
            status: 'success', // success exception
            startTime: '',
            speed: '',
            animation_id: ''
        };
    },
    created() {
        let ali = document.createElement('script');
        ali.src = '/static/aliyun-upload-sdk-1.5.0.min.js';
        let oss = document.createElement('script');
        oss.src = '/static/aliyun-oss-sdk-5.3.1.min.js';
        let s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(oss, s);
        s.parentNode.insertBefore(ali, s);
    },
    mounted() {
        if (this.type === 'edit') {
            this.uploadStatus = 'edit';
            this.disabledUpload = true;
            this.fileSize = this.file_size;
        }
        const { animation_id } = this.$route.query;
        this.animation_id = animation_id;
    },
    methods: {
        // 计算上传速度
        calcSpeed() {
            let t = (new Date() - this.startTime) / 1000;
            let size = parseInt(this.fileSize * this.authProgress / 100 / 1024 / t);
            if (size > 1024) {
                this.speed = `${(size / 1024).toFixed(2)}M/s`;
                return;
            }
            this.speed = `${size}KB/s`;
        },
        handleCancel() {
            this.$utils.confirm('', '确定取消上传视频吗？', '取消中...', (action, instance, done) => {
                this.$refs.uploader.clearFiles();
                this.uploadStatus = 'default';
                this.disabledUpload = false;
                this.uploader.cancelFile(0);
                this.uploader.cleanList();
                this.$message.success('已取消！');
            });
        },
        handleReupload() {
            this.$refs.uploader.clearFiles();
            this.uploadStatus = 'default';
            this.disabledUpload = false;
        },
        handleDelete() {
            this.$utils.confirm('', '确定删除上传视频吗？', '删除中...', (action, instance, done) => {
                this.$refs.uploader.clearFiles();
                this.$emit('update:correlation_video_id', '');
                if (this.uploader) {
                    this.uploader.stopUpload();
                    this.authProgress = 0;
                }
                this.uploadStatus = 'default';
                this.disabledUpload = false;
                this.$message.success('已删除！');
            });
        },
        // 预览章节视频
        previewHandle({ correlation_video_id }) {
            if (!this.link) {
                this.link = document.createElement('a');
            }
            this.$api('getRelatedVideoPlayAuth', {
                correlation_video_id
            }).then(res => {
                console.log(res);
                this.link.setAttribute('href', `/static/video_preview.html?playauth=${res.data.PlayAuth}&vid=${correlation_video_id}`);
                this.link.setAttribute('target', '_blank');
                this.link.click();
            });
        },
        // 获取视频上传token
        getRelatedVideoToken(filename, type) {
            return this.$api('getRelatedVideoToken', {filename, type}).then(res => (this.tokenData = { ...this.tokenData, ...res.data }));
        },
        // 视频选择
        onChange(file) {
            this.file = file.raw;
            if (!this.correlation_name) {
                this.$message.error('请先填写相关视频名称');
                this.$refs.uploader.clearFiles();
                return;
            }
            if (this.file.size > 1024 * 1024 * 1024) {
                this.$message.error('视频大小超标');
                this.$refs.uploader.clearFiles();
                return;
            }
            var userData = '{"Vod":{}}';
            if (this.uploader) {
                this.authProgress = 0;
                this.statusText = '';
            }
            this.uploader = this.createUploader();
            console.log(userData);
            this.uploader.addFile(this.file, null, null, null, userData);
            this.uploadDisabled = false;
            this.pauseDisabled = true;
            this.resumeDisabled = true;
            // 计算视频时长
            this.countVideoInfo(this.file);
        },
        // 本地上传时计算视频时长
        countVideoInfo(file) {
            let self = this;
            var video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = function() {
                window.URL.revokeObjectURL(video.src);
                var duration = parseInt(video.duration);
                self.$emit('update:duration', duration);
            };
            video.src = URL.createObjectURL(file);
        },
        // 自定义上传方法
        uploadFunction() {
            if (this.uploader !== null) {
                this.uploader.startUpload();
                this.uploadDisabled = true;
                this.pauseDisabled = false;
            }
        },
        createUploader (type) {
            let self = this;
            let uploader = new AliyunUpload.Vod({
                timeout: self.timeout || 60000,
                partSize: self.partSize || 1048576,
                parallel: self.parallel || 5,
                retryCount: self.retryCount || 3,
                retryDuration: self.retryDuration || 2,
                region: self.region,
                userId: self.userId,
                // 添加文件成功
                addFileSuccess: function (uploadInfo) {
                    self.uploadDisabled = true;
                    self.resumeDisabled = false;
                    self.fileSize = uploadInfo.file.size;
                    self.statusText = '添加文件成功, 等待上传...';
                    setTimeout(() => {
                        self.uploadFunction();
                    });
                    console.log('addFileSuccess: ' + uploadInfo.file.name);
                },
                // 开始上传
                onUploadstarted: function (uploadInfo) {
                    // 开始计时
                    self.startTime = new Date();
                    // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
                    // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
                    // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
                    // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
                    // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
                    // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
                    self.$api('getRelatedVideoToken', {
                        correlation_name: self.correlation_name,
                        file_name: uploadInfo.file.name
                    }).then(({data}) => {
                        let uploadAuth = data.UploadAuth;
                        let uploadAddress = data.UploadAddress;
                        let videoId = data.VideoId;
                        uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
                    });
                    self.statusText = '文件开始上传...';
                    console.log('onUploadStarted:' + uploadInfo.file.name + ', endpoint:' + uploadInfo.endpoint + ', bucket:' + uploadInfo.bucket + ', object:' + uploadInfo.object);
                },
                // 文件上传成功
                onUploadSucceed: function (uploadInfo) {
                    self.$emit('update:correlation_video_id', uploadInfo.videoId);
                    console.log('onUploadSucceed: ' + uploadInfo.file.name + ', endpoint:' + uploadInfo.endpoint + ', bucket:' + uploadInfo.bucket + ', object:' + uploadInfo.object);
                    self.statusText = '文件上传成功!';
                    self.uploadStatus = 'success';
                },
                // 文件上传失败
                onUploadFailed: function (uploadInfo, code, message) {
                    console.log('onUploadFailed: file:' + uploadInfo.file.name + ',code:' + code + ', message:' + message);
                    self.uploadStatus = 'failed';
                    self.statusText = '文件上传失败!';
                },
                // 取消文件上传
                onUploadCanceled: function (uploadInfo, code, message) {
                    console.log('Canceled file: ' + uploadInfo.file.name + ', code: ' + code + ', message:' + message);
                    self.statusText = '文件已暂停上传';
                },
                // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
                onUploadProgress: function (uploadInfo, totalSize, progress) {
                    self.calcSpeed();
                    console.log('onUploadProgress:file:' + uploadInfo.file.name + ', fileSize:' + totalSize + ', percent:' + Math.ceil(progress * 100) + '%');
                    let progressPercent = Math.ceil(progress * 100);
                    self.authProgress = progressPercent;
                    self.uploadStatus = 'uploading';
                    self.statusText = '文件上传中...';
                },
                // 上传凭证超时
                // onUploadTokenExpired: function (uploadInfo) {
                //     // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
                //     // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
                //     // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
                //     let refreshUrl = 'https://demo-vod.cn-shanghai.aliyuncs.com/voddemo/RefreshUploadVideo?BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=59ECA-4193-4695-94DD-7E1247288&AppVersion=1.0.0&Title=haha1&FileName=xxx.mp4&VideoId=' + uploadInfo.videoId
                //     axios.get(refreshUrl).then(({data}) => {
                //         let uploadAuth = data.UploadAuth;
                //         uploader.resumeUploadWithAuth(uploadAuth);
                //         console.log('upload expired and resume upload with uploadauth ' + uploadAuth);
                //     });
                //     self.statusText = '文件超时...';
                // },
                // 全部文件上传结束
                onUploadEnd: function (uploadInfo) {
                    console.log('onUploadEnd: uploaded all the files');
                    self.statusText = '文件上传完毕';
                }
            });
            return uploader;
        }
    },
    components: {
        customerImage
    }
};
</script>

<style lang="scss">
.video-uploader-box {
    display: flex;
    border: 1px dashed #dcdfe6;
    padding: 20px;
    .video-uploader{
        &-upload{
            margin-left: 30px;
            position: relative;
            .el-upload-dragger {
                overflow: visible;
            }
        }
        .el-icon-plus{
            color: #e4e7ea;
            font-size: 60px;
            margin-top: 40px;
        }
        .icon-play {
            color: rgb(179, 179, 179);
            font-size: 60px;
            margin-top: 50px;
        }
        .el-upload__text .text {
            font-size: 14px;
        }
        .el-upload__tip {
            color: #999;
        }
    }
    > .right {
      flex: auto;
      margin-left: 15px;
      > .hd {
        display: flex;
        align-items: center;
        .el-progress {
          flex: auto;
          margin-right: 20px;
        }
        .el-icon-success {
            color: #67C23A;
            font-size: 20px;
        }
        .text-success, .text-error {
            font-size: 14px;
            margin-right: 15px;
        }
        .el-icon-error {
            color: #f56c6c;
            font-size: 20px;
        }
      }
      > .info {
        color: #999;
      }
      > .meta {
        position: absolute;
        top: 175px;
        color: #666;
        span {
          font-size: 14px;
        }
      }
    }
}
</style>
