<template>
    <div class="acgn-customer-image__box">
        <i class="el-icon-success" v-if="greenprocess == 4"></i>
        <i class="el-icon-error" v-else-if="greenprocess == 3"></i>
        <i class="el-icon-warning" v-else-if="greenprocess === 2"></i>
        <i class="el-icon-more" v-else></i>
        <div v-if="isLoading" class="customer-image loading"></div>
        <img v-else :src="$utils.filterImagePath(imageUrl)" :class="{ 'vertical' : isVertical }">
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-10-26 09:01:50
 * @LastEditors: VSCode
 * @LastEditTime: 2018-10-26 09:55:38
 * @Description: image组件
 * @Company: 成都二次元动漫
 */
import imageError from '../..//static/images/error.jpg';

export default {
    props: {
        src: String,
        greenprocess: Number // 鉴黄状态 0初始状态/1待鉴定/2访问阿里失败/3未通过/4通过
    },
    data() {
        return {
            imageUrl: '',
            isLoading: false,
            isVertical: false
        };
    },
    created() {
        this.loadImage();
    },
    watch: {
        src() {
            this.loadImage();
        }
    },
    methods: {
        loadImage() {
            this.image = new Image();
            this.image.onload = event => {
                this.setImageShow(this.image);
                this.loadedImage();
                this.imageUrl = this.src;
            };
            this.image.onerror = event => {
                this.loadedImage();
                this.imageUrl = imageError;
            };
            this.image.src = this.src;
            this.isLoading = true;
        },
        loadedImage() {
            this.isLoading = false;
            this.image = null;
        },
        setImageShow(image) {
            const { width, height } = image;
            const ratio = width / height;
            this.isVertical = ratio < 1;
        }
    }
};
</script>
<style lang="scss">
    @import "@/styles/var.scss";
    .acgn-customer-image{
        &__box{
            height: 100%;
            position: relative;
            text-align: center;
            > i {
                position: absolute;
                font-size: 22px;
                right: 0;
                top: 0;
                transform: translate(50%, -50%);
                &.el-icon-success {
                    color: $color-success;
                }
                &.el-icon-error {
                    color: $color-danger;
                }
                &.el-icon-more {
                    font-size: 20px;
                    color: $color-black;
                    border:solid 1px $color-black;
                    border-radius: 50%;
                }
                &.el-icon-warning {
                    color: $color-warning;
                }
            }
            .customer-image{
                &.loading{
                    width: 100%;
                    height: 100%;
                    background-image: url('/static/images/image_loading.png');
                    background-position: center;
                    background-size: 40px 40px;
                    background-repeat: no-repeat;
                    display: inline-block;
                    animation: rotate 1s linear infinite;
                    border: none;
                }
            }
            .vertical{
                width: auto;
                height:100%;
            }
        }
    }
</style>
